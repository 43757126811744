"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnDelimiterEnum = exports.ColumnTypesEnum = void 0;
var ColumnTypesEnum;
(function (ColumnTypesEnum) {
    ColumnTypesEnum["STRING"] = "String";
    ColumnTypesEnum["NUMBER"] = "Number";
    ColumnTypesEnum["DATE"] = "Date";
    ColumnTypesEnum["EMAIL"] = "Email";
    ColumnTypesEnum["REGEX"] = "Regex";
    ColumnTypesEnum["SELECT"] = "Select";
    ColumnTypesEnum["ANY"] = "Any";
    ColumnTypesEnum["DOUBLE"] = "Double";
    ColumnTypesEnum["IMAGE"] = "Image";
})(ColumnTypesEnum = exports.ColumnTypesEnum || (exports.ColumnTypesEnum = {}));
var ColumnDelimiterEnum;
(function (ColumnDelimiterEnum) {
    ColumnDelimiterEnum["COMMA"] = ",";
    ColumnDelimiterEnum["SEMICOLON"] = ";";
})(ColumnDelimiterEnum = exports.ColumnDelimiterEnum || (exports.ColumnDelimiterEnum = {}));
