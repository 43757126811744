"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BubbleDestinationEnvironmentEnum = exports.DestinationsEnum = void 0;
var DestinationsEnum;
(function (DestinationsEnum) {
    DestinationsEnum["WEBHOOK"] = "webhook";
    DestinationsEnum["BUBBLEIO"] = "bubbleIo";
    DestinationsEnum["FRONTEND"] = "frontend";
})(DestinationsEnum = exports.DestinationsEnum || (exports.DestinationsEnum = {}));
var BubbleDestinationEnvironmentEnum;
(function (BubbleDestinationEnvironmentEnum) {
    BubbleDestinationEnvironmentEnum["DEVELOPMENT"] = "development";
    BubbleDestinationEnvironmentEnum["LIVE"] = "live";
})(BubbleDestinationEnvironmentEnum = exports.BubbleDestinationEnvironmentEnum || (exports.BubbleDestinationEnvironmentEnum = {}));
