"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventTypes = exports.ColumnTypes = void 0;
exports.ColumnTypes = {
    STRING: 'String',
    NUMBER: 'Number',
    DATE: 'Date',
    EMAIL: 'Email',
    REGEX: 'Regex',
    SELECT: 'Select',
    ANY: 'Any',
    DOUBLE: 'Double',
    IMAGE: 'Image',
};
exports.EventTypes = {
    INIT_IFRAME: 'INIT_IFRAME',
    WIDGET_READY: 'WIDGET_READY',
    CLOSE_WIDGET: 'CLOSE_WIDGET',
    AUTHENTICATION_VALID: 'AUTHENTICATION_VALID',
    AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
    UPLOAD_STARTED: 'UPLOAD_STARTED',
    UPLOAD_TERMINATED: 'UPLOAD_TERMINATED',
    UPLOAD_COMPLETED: 'UPLOAD_COMPLETED',
    DATA_IMPORTED: 'DATA_IMPORTED',
};
