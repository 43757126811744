"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMAIL_SUBJECT = exports.SCREENS = exports.DEFAULT_KEYS_OBJ = exports.DEFAULT_VALUES_OBJ = exports.DEFAULT_VALUES_ARR = exports.DEFAULT_VALUES = exports.Defaults = void 0;
const uuid_1 = require("uuid");
exports.Defaults = {
    ONE: 1,
    PAGE_LIMIT: 100,
    ZERO: 0,
    MINUS_ONE: -1,
    NOT_FOUND_STATUS_CODE: 404,
    READY_STATE: 1,
    CHUNK_SIZE: 100,
    DATE_FORMATS: ['DD/MM/YYYY'],
    DATE_FORMAT: 'DD/MM/YYYY',
};
exports.DEFAULT_VALUES = [
    {
        label: 'null',
        value: '<<null>>',
    },
    {
        label: 'undefined',
        value: '<<undefined>>',
    },
    {
        label: 'Empty String',
        value: '<<>>',
    },
    {
        label: 'Empty Array ([])',
        value: '<<[]>>',
    },
    {
        label: 'Boolean true',
        value: '<<true>>',
    },
    {
        label: 'Boolean false',
        value: '<<false>>',
    },
    {
        label: 'UUID v4',
        value: '<<uuid>>',
    },
];
exports.DEFAULT_VALUES_ARR = exports.DEFAULT_VALUES.reduce((acc, item) => {
    acc.push(item.value);
    return acc;
}, []);
exports.DEFAULT_VALUES_OBJ = {
    '<<null>>': null,
    '<<undefined>>': undefined,
    '<<>>': '',
    '<<[]>>': [],
    '<<true>>': true,
    '<<false>>': false,
    '<<uuid>>': uuid_1.v4,
};
exports.DEFAULT_KEYS_OBJ = {
    null: '<<null>>',
    undefined: '<<undefined>>',
    'Empty String': '<<>>',
    'Empty Array ([])': '<<[]>>',
    'Boolean true': '<<true>>',
    'Boolean false': '<<false>>',
    'UUID v4': '<<uuid>>',
};
var SCREENS;
(function (SCREENS) {
    SCREENS["VERIFY"] = "verify";
    SCREENS["ONBOARD"] = "onboard";
    SCREENS["HOME"] = "home";
})(SCREENS = exports.SCREENS || (exports.SCREENS = {}));
var EMAIL_SUBJECT;
(function (EMAIL_SUBJECT) {
    EMAIL_SUBJECT["ERROR_SENDING_BUBBLE_DATA"] = "\uD83D\uDED1 Encountered error while sending data to Bubble in";
    EMAIL_SUBJECT["ERROR_EXECUTING_VALIDATION_CODE"] = "\uD83D\uDED1 Encountered error while executing validation code in";
    EMAIL_SUBJECT["ERROR_SENDING_WEBHOOK_DATA"] = "\uD83D\uDED1 Encountered error while sending webhook data in";
    EMAIL_SUBJECT["VERIFICATION_CODE"] = "Your Verification Code for Impler";
    EMAIL_SUBJECT["RESET_PASSWORD"] = "Reset Password | Impler";
})(EMAIL_SUBJECT = exports.EMAIL_SUBJECT || (exports.EMAIL_SUBJECT = {}));
