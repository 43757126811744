"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENVTypesEnum = exports.ACCESS_KEY_NAME = void 0;
exports.ACCESS_KEY_NAME = 'accesskey';
exports.ENVTypesEnum = {
    DEV: 'dev',
    TEST: 'test',
    PROD: 'prod',
    CI: 'ci',
    LOCAL: 'local',
};
