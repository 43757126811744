"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertStringToJson = exports.constructQueryString = exports.downloadFile = exports.replaceVariablesInString = exports.numberFormatter = exports.getErrorObject = exports.isBrowser = exports.changeToCode = void 0;
const client_1 = require("@impler/client");
const changeToCode = (str = '') => {
    var _a, _b;
    return (_b = (_a = str === null || str === void 0 ? void 0 : str.replace(/[^\s\w]/gi, '')) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === null || _b === void 0 ? void 0 : _b.replace(/ /g, '_');
};
exports.changeToCode = changeToCode;
function isBrowser() {
    return typeof window !== 'undefined';
}
exports.isBrowser = isBrowser;
function getErrorObject(error) {
    if (!error)
        return {};
    const errorStrs = error.split(`, `);
    let fieldName;
    return errorStrs.reduce((acc, val) => {
        [, fieldName] = val.split(/`/);
        acc[fieldName] = val;
        return acc;
    }, {});
}
exports.getErrorObject = getErrorObject;
const defaultDigits = 2;
function numberFormatter(num, digits = defaultDigits) {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(function (lookupItem) {
        return num >= lookupItem.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}
exports.numberFormatter = numberFormatter;
function replaceVariablesInString(str, obj) {
    return str.replace(/{([^{}]*)}/g, function (a, b) {
        const value = obj[b];
        return typeof value === 'string' || typeof value === 'number' ? value : a;
    });
}
exports.replaceVariablesInString = replaceVariablesInString;
function downloadFile(blob, name) {
    var _a;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    (_a = link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
}
exports.downloadFile = downloadFile;
function constructQueryString(obj) {
    const arr = [];
    Object.keys(obj).forEach((key) => {
        if (obj[key] !== undefined && obj[key] !== null)
            arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    });
    const query = arr.join('&');
    return query ? `?${query}` : '';
}
exports.constructQueryString = constructQueryString;
const convertStringToJson = (value) => {
    if ((0, client_1.isObject)(value))
        return value;
    try {
        return JSON.parse(value);
    }
    catch (e) {
        return undefined;
    }
};
exports.convertStringToJson = convertStringToJson;
