"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileNotExistError = void 0;
class FileNotExistError extends Error {
    constructor(name) {
        super('File not found for the key provided ' + name);
        this.name = 'NonExistingFileError';
    }
}
exports.FileNotExistError = FileNotExistError;
