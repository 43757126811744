"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRecordFormat = exports.validateVariable = exports.updateDefaultValues = exports.replaceVariablesInObject = exports.replaceVariable = exports.replaceVariableInString = exports.replaceVariableInStringWithKey = exports.createVariable = exports.updateCombinedFormat = exports.createRecordFormat = void 0;
const defaults_1 = require("./defaults");
const tabWidth = 2;
function createRecordFormat(variables, extraParams = {}) {
    const recordFormat = variables.reduce((acc, variable) => {
        return Object.assign(Object.assign({}, acc), { [variable]: createVariable(variable) });
    }, extraParams);
    return JSON.stringify(recordFormat, null, tabWidth);
}
exports.createRecordFormat = createRecordFormat;
function updateCombinedFormat(format, variables) {
    const combinedFormat = JSON.parse(format);
    const regex = /%.*?%/g;
    const recordFormat = variables.reduce((acc, variable) => {
        return Object.assign(Object.assign({}, acc), { [variable]: createVariable(variable) });
    }, {});
    Object.keys(combinedFormat).forEach((key) => {
        if (regex.test(key) && typeof combinedFormat[key] === 'object' && !Array.isArray(combinedFormat[key])) {
            combinedFormat[key] = recordFormat;
        }
    });
    return JSON.stringify(combinedFormat, null, tabWidth);
}
exports.updateCombinedFormat = updateCombinedFormat;
function createVariable(name) {
    return `{{${name}}}`;
}
exports.createVariable = createVariable;
function replaceVariableInStringWithKey(str, key, value) {
    return str.replace(createVariable(key), value);
}
exports.replaceVariableInStringWithKey = replaceVariableInStringWithKey;
function replaceVariableInString(str, record) {
    const regex = /{{.*?}}/g;
    let modifiedStr = str;
    const keys = Object.keys(record);
    const matches = str.match(regex);
    for (const match of matches) {
        const key = match.replace(/{{|}}/g, '');
        if (keys.includes(key)) {
            if (str === match) {
                modifiedStr = record[key];
            }
            else if (typeof modifiedStr === 'string') {
                modifiedStr = replaceVariableInStringWithKey(modifiedStr, key, record[key]);
            }
        }
    }
    return modifiedStr;
}
exports.replaceVariableInString = replaceVariableInString;
function replaceVariable(formatKey, key, value, record) {
    if (typeof formatKey == 'string' && /{{|}}/g.test(formatKey))
        return replaceVariableInString(formatKey, record);
    else if (typeof formatKey === 'object' && !Array.isArray(formatKey) && formatKey !== null) {
        return replaceVariablesInObject(formatKey, record);
    }
    else if (Array.isArray(formatKey)) {
        return formatKey.map((item) => replaceVariable(item, key, value, record));
    }
    return formatKey;
}
exports.replaceVariable = replaceVariable;
function replaceVariablesInObject(format, record, defaultValues) {
    record = updateDefaultValues(record, defaultValues);
    return Object.keys(format).reduce((acc, key) => {
        acc[key] = replaceVariable(format[key], key, format[key], record);
        return acc;
    }, {});
}
exports.replaceVariablesInObject = replaceVariablesInObject;
function updateDefaultValues(record, defaultValues) {
    if (defaultValues) {
        Object.keys(defaultValues).forEach((key) => {
            if (typeof record[key] === 'undefined') {
                if (typeof defaultValues[key] === 'string' && defaults_1.DEFAULT_VALUES_ARR.includes(defaultValues[key])) {
                    record[key] =
                        typeof defaults_1.DEFAULT_VALUES_OBJ[defaultValues[key]] === 'function'
                            ? defaults_1.DEFAULT_VALUES_OBJ[defaultValues[key]]()
                            : defaults_1.DEFAULT_VALUES_OBJ[defaultValues[key]];
                }
                else {
                    record[key] = defaultValues[key];
                }
            }
        });
    }
    return record;
}
exports.updateDefaultValues = updateDefaultValues;
function validateVariable(name) {
    return /{{|}}/g.test(name);
}
exports.validateVariable = validateVariable;
function getRecordFormat(chunkFormat) {
    const format = JSON.parse(chunkFormat);
    const regex = /%.*?%/g;
    let recordFormat = null;
    Object.keys(format).forEach((key) => {
        if (regex.test(key) && typeof format[key] === 'object') {
            recordFormat = format[key];
            delete format[key];
            format[key.slice(1, -1)] = createVariable('data');
        }
        else if (regex.test(key) && Array.isArray(format[key])) {
            throw new Error('Array format not supported for record.');
        }
        else if (Array.isArray(format[key])) {
            format[key] = format[key].map((item) => {
                if (typeof item === 'string' && regex.test(item)) {
                    return createVariable(item.slice(1, -1));
                }
                else if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
                    const arrFormat = getRecordFormat(JSON.stringify(item));
                    if (arrFormat && arrFormat.chunkFormat) {
                        recordFormat = arrFormat.recordFormat;
                        return arrFormat.chunkFormat;
                    }
                    return item;
                }
                else {
                    return item;
                }
            });
        }
        else if (typeof format[key] === 'object' && !Array.isArray(format[key]) && format[key] !== null) {
            const nestedFormats = getRecordFormat(JSON.stringify(format[key]));
            if (nestedFormats && nestedFormats.recordFormat) {
                format[key] = nestedFormats.chunkFormat;
                recordFormat = nestedFormats.recordFormat;
            }
        }
    });
    if (!recordFormat && recordFormat === null)
        return null;
    return {
        recordFormat: JSON.stringify(recordFormat),
        chunkFormat: JSON.stringify(format),
    };
}
exports.getRecordFormat = getRecordFormat;
