"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserJobImportStatusEnum = void 0;
var UserJobImportStatusEnum;
(function (UserJobImportStatusEnum) {
    UserJobImportStatusEnum["PAUSED"] = "PAUSED";
    UserJobImportStatusEnum["RUNNING"] = "RUNNING";
    UserJobImportStatusEnum["MAPPING"] = "MAPPING";
    UserJobImportStatusEnum["SCHEDULING"] = "SCHEDULING";
    UserJobImportStatusEnum["TERMINATED"] = "TERMINATED";
})(UserJobImportStatusEnum = exports.UserJobImportStatusEnum || (exports.UserJobImportStatusEnum = {}));
